import styled from '@emotion/styled';

import { Divider as DividerComponent } from '@/components/common/Divider';
import { WidthConstraint } from '@/components/layout/Section';

import type { SanityDividerType } from '@/types/sanity';

export interface DividerProps extends SanityDividerType {}

export const Divider = ({ orientation }: DividerProps) => {
  return (
    <StyledSection>
      <WidthConstraint>
        <DividerComponent orientation={orientation} />
      </WidthConstraint>
    </StyledSection>
  );
};

const StyledSection = styled.section`
  margin-top: -1px;
`;
